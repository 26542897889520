/* Add here all your css styles (customizations) */
@import "helvetica_now";

// Variables
@import "custom.variables";

// Mixins
@import "custom.mixins";

ul.squarelist, ul.squarelist li {
	list-style-type: square;
}
footer {
	font-size: 0.9rem;
}

/* Background */
.g-theme-bg-gray-light-v1 {
	background-color: #333337;
  }
  
  .g-theme-bg-blue-dark-v2 {
	background-color: #333337;
  }
  
  .g-bg-darkblue-opacity-0_7 {
	background-color: rgba(#333337, .7) !important;
  
	&--after:after {
	  background-color: rgba(#333337, .7) !important;
	}
  }
  
  .g-bg-darkblue-opacity-0_7--after {
	&::after {
	  background-color: rgba(#333337, .7) !important;
	}
  }
  
  .g-bg-darkblue-opacity-0_9 {
	background-color: rgba(#333337, .9) !important;
  }


  /* Add here all your css styles (customizations) */
ul.squarelist, ul.squarelist li {
  list-style-type: square;
}

footer {
  font-size: 0.9rem;
}

/* Background */
.g-theme-bg-gray-light-v1 {
  background-color: #333337;
}

.g-theme-bg-blue-dark-v2 {
  background-color: #333337;
}

.g-bg-darkblue-opacity-0_7 {
  background-color: rgba(51, 51, 55, 0.7) !important;
}

.g-bg-darkblue-opacity-0_7--after:after {
  background-color: rgba(51, 51, 55, 0.7) !important;
}

.g-bg-darkblue-opacity-0_7--after::after {
  background-color: rgba(51, 51, 55, 0.7) !important;
}

.g-bg-darkblue-opacity-0_9 {
  background-color: rgba(51, 51, 55, 0.9) !important;
}



/* Add here all your css styles (customizations) */
ul.squarelist, ul.squarelist li {
  list-style-type: square;
}

footer {
  font-size: 0.9rem;
}

/* Background */
.g-theme-bg-gray-light-v1 {
  background-color: #333337;
}

.g-theme-bg-blue-dark-v2 {
  background-color: #333337;
}

.g-bg-darkblue-opacity-0_7 {
  background-color: rgba(51, 51, 55, 0.7) !important;
}

.g-bg-darkblue-opacity-0_7--after:after {
  background-color: rgba(51, 51, 55, 0.7) !important;
}

.g-bg-darkblue-opacity-0_7--after::after {
  background-color: rgba(51, 51, 55, 0.7) !important;
}

.g-bg-darkblue-opacity-0_9 {
  background-color: rgba(51, 51, 55, 0.9) !important;
}



// new 2020 after new CD

h2,
.h2,
h3,
.h3 {
  color:#252525;
}

.color-darkgray {
  color:#252525!important;
}

.border-g-primary,
.form-control.border-g-primary {
  border-color:$g-color-primary;
}

.footer-bottomAlt {
    font-size: 14px;
    line-height: 1.65714286em;
    //background-color: $fiw-dark-gray;
    background-color: #676c74;
    color: #e6e6e6;

    a {
        color: #e6e6e6;
    }

    a:hover {
        text-decoration: underline;
    }
    padding: 15px 0;
    padding-top: 20px;

    span {
        display: block;
        text-align: right;
    }
}
.cookiealert-container {
  font-size: 14px;
  line-height: 1.65714286em;
  //background-color: $fiw-dark-gray;
  //background-color: #676c74;
  //color: #e6e6e6;
}


#app {
      background: url(/images/fiw/flying-boxes-1b.svg) no-repeat;
      background-position: right 0px bottom 56px;
}